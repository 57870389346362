@font-face {
  font-family: "CrimsonText-Regular";
  src: url("CrimsonText-Regular.eot");
  src: url("CrimsonText-Regular.eot?#iefix") format("embedded-opentype"),
    url("CrimsonText-Regular.woff2") format("woff2"),
    url("CrimsonText-Regular.woff") format("woff"),
    url("CrimsonText-Regular.ttf") format("truetype"),
    url("CrimsonText-Regular.svg#CrimsonText-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CrimsonText-Italic";
  src: url("CrimsonText-Italic.eot");
  src: url("CrimsonText-Italic.eot?#iefix") format("embedded-opentype"),
    url("CrimsonText-Italic.woff2") format("woff2"),
    url("CrimsonText-Italic.woff") format("woff"),
    url("CrimsonText-Italic.ttf") format("truetype"),
    url("CrimsonText-Italic.svg#CrimsonText-Italic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("Montserrat-Regular.eot");
  src: url("Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
    url("Montserrat-Regular.woff2") format("woff2"),
    url("Montserrat-Regular.woff") format("woff"),
    url("Montserrat-Regular.ttf") format("truetype"),
    url("Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("Montserrat-Medium.eot");
  src: url("Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
    url("Montserrat-Medium.woff2") format("woff2"),
    url("Montserrat-Medium.woff") format("woff"),
    url("Montserrat-Medium.ttf") format("truetype"),
    url("Montserrat-Medium.svg#Montserrat-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}
