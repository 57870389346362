@import "fonts/fonts.css";

* {
  box-sizing: border-box;
  margin: 0;
  font-family: "CrimsonText-Regular", sans-serif;
}

html {
  scroll-behavior: unset !important;
}

body {
  margin: 0;
  font-family: "CrimsonText-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fffff0;
  color: #8080ff;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}

a,
a:hover {
  text-decoration: none;
}

button {
  all: unset;
}

div[type="button"],
div[type="submit"] {
  appearance: none;
}
